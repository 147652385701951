const render = () => {
  return Promise.all([
    import(/* webpackChunkName: "react" */ "react"),
    import(/* webpackChunkName: "react" */ "react-dom"),
    import(/* webpackChunkName: "app" */ "src/App.jsx")
  ])
    .then(([React, ReactDom, App]) => {
      const el = document.getElementById("app");

      ReactDom.unmountComponentAtNode(el);
      ReactDom.render(<App.default />, el);
    })
    .catch(err => {
      throw err;
    });
};

if (module.hot) {
  module.hot.accept("src/App.jsx", () => {
    setTimeout(render, 0);
  });
}

render();
